import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function TestCaseManagement() {
    const [testCases, setTestCases] = useState([]);
    // eslint-disable-next-line
    const [error, setError] = useState(null);
    const [url, setUrl] = useState('');
    const [pname, setProjectName] = useState('');
    const [file, setFile] = useState('');
    // const [testStatus, setTestStatus] = useState([]); // Add testStatus state
    const [recordVideo, setRecordVideo] = useState(false);
    const [parallelExecution, setparallelExecution] = useState(false);
    const [selectedBrowser, setSelectedBrowser] = useState('chrome'); // Add browser selection state
    const [selectedTests, setSelectedTests] = useState([]); // State to track selected tests
    const [progress, setProgress] = useState(0); // State for progress bar
    const AISERVICE = process.env.REACT_APP_AISERVICE;
    const [selectAll, setSelectAll] = useState(false); // State for "Select All" checkbox
    const [loading, setLoading] = useState(false);
    const [buttonGenDisabled, setButtonGenDisabled] = useState(false); // New state
    const [row2Visible, setRow2Visible] = useState(false);
    const [row3Visible, setRow3Visible] = useState(false);
    const [row4Visible, setRow4Visible] = useState(false);
    const [automating, setAutomating] = useState(false);
    const [buttonAutomateDisabled, setButtonAutomateDisabled] = useState(false); // New state
    const [logContent, setLogContent] = useState(''); // New state for log file content


    useEffect(() => {
        // Fetch test cases if needed (e.g., from URL and file)
    }, []);


    const handleGenerate = async () => {
        setError(null);
        if (url && file && pname) {
            setLoading(true); // Show spinner
            setButtonGenDisabled(true)  //Disable Button
            const formData = new FormData();
            formData.append('url', url);
            formData.append('file', file);
            formData.append('pname', pname);

            try {
                const response = await axios.post(AISERVICE + '/api/generate-tests', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                setTestCases(response.data.testCases.map(test => ({ description: test, status: 'Designed' })));
            } catch (error) {
                alert("Error generating test cases: " + (error.response ? error.response.data.error : error.message));
                setError(error);
            } finally {
                setLoading(false); // Hide spinner
                setRow2Visible(true);
                setRow3Visible(true);
            }
        } else {
            alert('Please provide URL and file!');
        }
    };


    const executeSingleTest = async (testDescription, index) => {
        try {
            const formData = new FormData();
            formData.append('testDescription', testDescription);
            formData.append('recordVideo', recordVideo);
            formData.append('parallelExecution', parallelExecution);
            formData.append('selectedBrowser', selectedBrowser);
            formData.append('url', url);
            formData.append('file', file); // Append the file using FormData
            formData.append('pname', pname)


            const response = await axios.post(AISERVICE + '/api/execute-test', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Very Important!
                }
            });
            return response.data.testResult;
        } catch (error) {
            alert("Error executing test:", error);
            return "Failed";
        }
    };

    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);
        setSelectedTests(selectAll ? [] : testCases.map((_, index) => index));
    };

    const handleCheckboxChange = (index) => {
        setSelectedTests((prevSelectedTests) => {
            const updatedSelectedTests = [...prevSelectedTests];
            const indexExists = updatedSelectedTests.indexOf(index);

            if (indexExists === -1) {
                updatedSelectedTests.push(index);
            } else {
                updatedSelectedTests.splice(indexExists, 1);
            }
            return updatedSelectedTests;
        });
    };
    const handleRunSelectedTests = async () => {
        setError(null);
        setProgress(2); // Reset progress bar on start

        if (selectedTests.length === 0) {
            alert("Please select at least one test case to run.");
            return;
        }

        if (url === '' || file === '') {
            alert("Enter URL and upload a placeholder file.");
            return;
        }

        let completedTests = 0;
        setRow4Visible(true);
        setAutomating(true)
        setButtonAutomateDisabled(true)
        // Loop through selected tests and set status to 'Automating'
        for (const index of selectedTests) {
            setTestCases((prevTestCases) => {
                const updatedTestCases = [...prevTestCases];
                updatedTestCases[index].status = 'Automating...'; // Set the status to "Automating"
                return updatedTestCases;
            });

            // Execute the test
            const result = await executeSingleTest(testCases[index].description, index);

            // Update the status of the test once execution completes
            setTestCases((prevTestCases) => {
                const updatedTestCases = [...prevTestCases];
                updatedTestCases[index].status = result; // Set the status to the result ("Passed", "Failed", etc.)
                updatedTestCases[index].logFile = `/${testCases[index].description.replace(/ /g, '_')}.py`; // Add logFile
                console.log(updatedTestCases[index].logFile)
                return updatedTestCases;
            });

            completedTests++;
            setProgress(Math.round((completedTests / selectedTests.length) * 100)); // Update progress
        }
        setProgress(100); // Set progress to 100% after completion
        setAutomating(false)
    };

    const getStatusClass = (status) => {
        switch (status) {
            case 'Passed':
                return 'status-passed'; // Green color for Passed
            case 'Failed':
                return 'status-failed'; // Red color for Failed
            case 'Automating...':
                return 'status-automating'; // Orange color for Automating
            default:
                return 'status-default'; // Default color for other statuses
        }
    };

    const handleViewLog = async (logFile) => {
        try {
            const response = await fetch(AISERVICE + `/logs/${logFile}`); //Updated Fetch URL
            const text = await response.text();
            console.log(text)
            const logFileContent = text;
            setRow4Visible(true);
            setLogContent(logFileContent);
        } catch (error) {
            alert(`Error fetching log file: ${error}`);
        }
    };


    return (
        <div className='.main-container'>
            <div className="row row1">
                <div className="main-input">
                    <div className="form-group"> {/* Container for URL input */}
                        <label htmlFor="url">URL:</label>
                        <input
                            type="text"
                            id="url"
                            className="form-control"
                            placeholder="Enter URL"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                        />
                    </div>
                    <div className="form-group"> {/* Container for URL input */}
                        <label htmlFor="url">Project Name:</label>
                        <input
                            type="text"
                            id="pname"
                            className="form-control"
                            placeholder="Enter Project Name"
                            value={pname}
                            onChange={(e) => setProjectName(e.target.value)}
                        />
                    </div>
                    <div className="form-group"> {/* Container for file input */}
                        <label htmlFor="file">File:</label>
                        <input
                            type="file"
                            id="file"
                            className="form-control"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                    </div>

                </div>
                <button className="btn btn-primary" onClick={handleGenerate} disabled={buttonGenDisabled}>
                    {loading ? 'Generating...' : 'Generate Test Plan'}
                </button>
                {loading && (
                    <div className="spinner-container">
                        <div className="spinner"></div>
                    </div>
                )}
            </div>
            <div className="row row2" style={{ display: row2Visible ? 'flex' : 'none' }}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>
                                <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAllChange}
                                />Test Plan
                            </th>
                            <th>Status</th>
                            <th>View Log</th> {/* Added Header */}
                        </tr>
                    </thead>
                    <tbody>
                        {testCases.map((test, index) => (
                            <tr key={index}>
                                <td>
                                    <div className="select-test-wrapper">
                                        <input
                                            type="checkbox"
                                            checked={selectedTests.includes(index)}
                                            onChange={() => handleCheckboxChange(index)}
                                        />
                                        {test.description}
                                    </div>
                                </td>
                                <td>
                                    {/* Apply the status color based on the result */}
                                    <span className={getStatusClass(test.status)}>{test.status}</span>
                                </td>

                                <td>
                                    {test.logFile && (
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault(); // Prevent default link behavior
                                            handleViewLog(test.logFile);
                                        }}>View</a>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="row row3" style={{ display: row3Visible ? 'flex' : 'none' }}>
                <div className="toggle-wrapper">
                    <label className="toggle-label" htmlFor="recordVideo">
                        Record Video
                    </label>
                    <input
                        type="checkbox"
                        id="recordVideo"
                        className="toggle-checkbox"
                        checked={recordVideo}
                        onChange={() => setRecordVideo(!recordVideo)}
                    />
                </div>
                <div className="toggle-wrapper">
                    <label className="toggle-label" htmlFor="recordVideo">
                        Parallel Execution
                    </label>
                    <input
                        type="checkbox"
                        id="parallelExecution"
                        className="toggle-checkbox"
                        checked={parallelExecution}
                        onChange={() => setparallelExecution(!parallelExecution)}
                        disabled={true} // Permanently disabled
                    />
                </div>
                <div className="toggle-wrapper">
                    <div className="browser-select"> {/* Added container for dropdown */}
                        <label htmlFor="browserSelect">Select Browser</label>
                        <select
                            id="browserSelect"
                            value={selectedBrowser}
                            onChange={(e) => setSelectedBrowser(e.target.value)}
                            disabled={true} // Permanently disabled
                        >
                            <option value="chrome">Chrome</option>
                            <option value="webkit">WebKit</option>
                            <option value="firefox">Firefox</option>
                        </select>
                    </div>
                </div>
                <button className="btn btn-primary" onClick={handleRunSelectedTests} disabled={buttonAutomateDisabled}>{automating ? 'Automating...' : 'Automate Selected Tests'}</button>
                <div className="progress-bar-container">
                    <CircularProgressbar
                        value={progress}
                        text={`${progress}%`}
                        styles={buildStyles({
                            rotation: 0.25,
                            strokeLinecap: 'round',
                            textSize: '25px',
                            pathTransitionDuration: 0.5,
                            pathColor: `rgba(16, 117, 79, ${progress / 100})`,
                            textColor: '#000000',
                            trailColor: '#d6d6d6',
                            backgroundColor: '#3e4451',
                        })}
                    />
                </div>


            </div>
            <div className="row row4" style={{ display: row4Visible ? 'flex' : 'none' }}>
                <div className="column-row4 log-content-column"> {/* First column for log content */}
                    {logContent && (

                        <pre><code class="python">{logContent}</code>
                        </pre>
                    )}
                </div>

            </div>
        </div>
    );
}

export default TestCaseManagement;