import React, { useState, useEffect } from 'react';

const Collapsible = ({ children, title, isOpen }) => {
    const [collapsed, setCollapsed] = useState(true);

    // Sync internal state with the `isOpen` prop
    useEffect(() => {
        setCollapsed(!isOpen); // `isOpen` true means `collapsed` false
    }, [isOpen]);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div className="collapsible" style={{ padding: '0px', marginBottom: '0px' }}>
            <div className="collapsible-header" onClick={toggleCollapse}>
                <span>{title}</span>
                <span className="collapsible-icon">{collapsed ? '▼' : '▲'}</span>
            </div>
            <div
                className="collapsible-content"
                style={{
                    display: collapsed ? 'none' : 'block',
                    backgroundColor: '#2b2b2b',
                    marginBottom: '0px',
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default Collapsible;
