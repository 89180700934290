import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Collapsible from './Collapsible'; // Import your Collapsible component
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs';


function ViewArtefacts() {
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedTestCase, setSelectedTestCase] = useState('');
    const [testCaseContent, setTestCaseContent] = useState('');
    const [projects, setProjects] = useState([]);
    const [testCases, setTestCases] = useState([]);
    const [error, setError] = useState(null);
    const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false); // State to control collapsible
    const [isCollapsibleOpen1, setIsCollapsibleOpen1] = useState(false);
    const [isCollapsibleOpen2, setIsCollapsibleOpen2] = useState(false);

    const fetchTestCaseContent = async (projectName, testCaseName) => {
        try {
            const response = await axios.get(process.env.REACT_APP_AISERVICE + `/api/testcasecontent/${projectName}/${testCaseName}`);
            setTestCaseContent(response.data.content);
            //setTestCaseContent(await response.text());
            //const setTestCaseContent = await response.text();
        } catch (error) {
            alert(`Error fetching test case content: ${error.message}`);
        }
    };



    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_AISERVICE + '/api/projects');
                setProjects(response.data.projects);
            } catch (err) {
                alert(`Error fetching projects: ${err.message}`);
            }
        };

        fetchProjects();
    }, []);

    useEffect(() => {
        const fetchTestCases = async () => {
            if (selectedProject) {
                try {
                    const response = await axios.get(process.env.REACT_APP_AISERVICE + `/api/testcases/${selectedProject}`);
                    setTestCases(response.data.testCases);
                    console.log(response.data.testCases)
                } catch (err) {
                    alert(`Error fetching test cases for project "${selectedProject}": ${err.message}`);
                }
            } else {
                setTestCases([]);
            }
        };

        fetchTestCases();
    }, [selectedProject]);

    useEffect(() => {
        if (selectedProject && selectedTestCase) {
            const fileName = selectedTestCase.replace(/\s+/g, '_') + '.py'; // Replace spaces with underscores
            fetchTestCaseContent(selectedProject, fileName);

            // Automatically expand the collapsible when a test case is selected
            setIsCollapsibleOpen(true);
        } else {
            setTestCaseContent('');
            setIsCollapsibleOpen(false);
        }
    }, [selectedProject, selectedTestCase]);

    return (
        <div className="view-artefacts">
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div className="artefact-rows">
                <div className="artefact-row">
                    <div className="artefact-column">
                        <label htmlFor="projectSelect">Project ID/Name:</label>
                        <select
                            id="projectSelect"
                            value={selectedProject}
                            onChange={(e) => setSelectedProject(e.target.value)}
                        >
                            <option value="">Select Project</option>
                            {projects.map((project) => (
                                <option key={project} value={project}>{project}</option>
                            ))}
                        </select>
                    </div>
                    <div className="artefact-column">
                        <label htmlFor="testCaseSelect">Test Case Description:</label>
                        <select
                            id="testCaseSelect"
                            value={selectedTestCase}
                            onChange={(e) => setSelectedTestCase(e.target.value)}
                        >
                            <option value="">Select Test Case</option>
                            {testCases.map((testCase) => (
                                <option key={testCase} value={testCase}>{testCase}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="artefact-row" style={{ padding: '0px', 'margin-bottom': '0px' }}>

                    <div className="artefact-column" >
                        <Collapsible title={'Test Script'} isOpen={isCollapsibleOpen} style={{ 'background-color': '#2b2b2b' }}>
                            <div className="artefact-content" style={{ maxHeight: 'none', 'background-color': '#2b2b2b', 'font-size': '16px' }}>
                                {/* Content to be displayed inside the collapsible section */}
                                <code>   <SyntaxHighlighter language="python" style={darcula} showLineNumbers="true">
                                    {testCaseContent}
                                </SyntaxHighlighter></code>
                            </div>
                        </Collapsible>

                    </div>
                </div>

            </div>
            <div className="artefact-row" style={{ padding: '0px', 'margin-bottom': '0px' }}>
                <div className="artefact-column" >
                    <Collapsible title={'Test Plan Generated by Nota AI'} isOpen={isCollapsibleOpen1} style={{ 'background-color': '#00000' }}>
                        <div className="artefact-content" style={{ maxHeight: 'none', 'background-color': '#00000', 'font-size': '16px' }}>
                            {/* Content to be displayed inside the collapsible section */}
                            <code>
                                {testCaseContent}
                            </code>
                        </div>
                    </Collapsible>

                </div>
            </div>

            <div className="artefact-row" style={{ padding: '0px', 'margin-bottom': '0px' }}>
                <div className="artefact-column" >
                    <Collapsible title={'Reasoning Generated by Nota AI'} isOpen={isCollapsibleOpen2} style={{ 'background-color': '#00000' }}>
                        <div className="artefact-content" style={{ maxHeight: 'none', 'background-color': '#00000 !important', 'font-size': '16px' }}>
                            {/* Content to be displayed inside the collapsible section */}
                            <code>
                                {testCaseContent}
                            </code>
                        </div>
                    </Collapsible>

                </div>
            </div>


        </div>

    );
}

export default ViewArtefacts;