import { NavLink } from 'react-router-dom';
import notaLogo from '../images/image_nota.webp';

function Sidebar() {
    return (
        <aside className="sidebar">
            <div className="logo-container">
                <img src={notaLogo} alt="NotaAI Logo" />
            </div>
            <nav>
                <ul>
                    <li><NavLink to="/automation">Generate Test Coverage</NavLink></li>
                    <li><NavLink to="/view-artefacts">View Artefacts</NavLink></li>
                </ul>
            </nav>
        </aside>
    );
}

export default Sidebar;