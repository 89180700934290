import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/sidebar';
import TestCaseManagement from './components/TestCaseManagement';
import ViewArtefacts from './components/ViewArtefacts';

function App() {
    return (
        <Router>
            <div className="app-container">
                <div className="columns">
                    <div className="column sidebar-column">
                        <Sidebar />
                    </div>
                    <div className="column main-column">
                        <Routes>
                            <Route path="/automation" element={<TestCaseManagement />} /> {/* Route for Generate Test Coverage */}
                            <Route path="/view-artefacts" element={<ViewArtefacts />} /> {/* Route for View Artefacts */}
                            {/*<Route path="*" element={<div>404 Not Found</div>} /> {Catch-all route for invalid paths }*/}

                        </Routes>
                    </div>
                </div>
            </div>
        </Router>
    );
}

export default App;